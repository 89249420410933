import { ColorLabel } from '@capturi/charts'
import { Segment } from '@capturi/filters'
import { Highlight } from '@capturi/ui-components'
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Plural, Trans } from '@lingui/macro'
import React from 'react'

const formatValue = (value: number): string =>
  i18n.number(value, {
    style: 'decimal',
    maximumFractionDigits: 0,
  })

type SingleSegmentOverviewProps = {
  color: string
  conversations: number
  conversationsTotal: number
}

export const SingleSegmentOverview: React.FC<SingleSegmentOverviewProps> = ({
  color,
  conversations,
  conversationsTotal,
}) => {
  return (
    <Flex align="center" as="li">
      {color && <ColorLabel color={color} mr={1} />}
      <Text>
        {conversations === conversationsTotal ? (
          <Plural
            value={conversationsTotal}
            one="The statistics are based on # conversation"
            other="The statistics are based on # conversations"
          />
        ) : (
          <Plural
            value={conversationsTotal}
            one={
              <Trans>
                The statistics are based on{' '}
                <Highlight>{formatValue(conversations)}</Highlight> out of #
                conversation
              </Trans>
            }
            other={
              <Trans>
                The statistics are based on{' '}
                <Highlight>{formatValue(conversations)}</Highlight> out of #
                conversations
              </Trans>
            }
          />
        )}
      </Text>
    </Flex>
  )
}

type DataBasisOverviewProps = {
  segments: Segment<{ conversations: number; conversationsTotal: number }>[]
} & BoxProps

const DataBasisOverview: React.FC<DataBasisOverviewProps> = ({
  segments,
  ...restBoxProps
}) => {
  return (
    <Box as="ul" {...restBoxProps}>
      {segments.map(
        (s) =>
          s.data &&
          s.data.conversations > 0 && (
            <SingleSegmentOverview
              key={s.label}
              color={s.color}
              conversations={s.data.conversations}
              conversationsTotal={s.data.conversationsTotal}
            />
          ),
      )}
    </Box>
  )
}

export default DataBasisOverview
